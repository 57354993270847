import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  useEvent,
  useEventsOnViewLoad
} from '@emerald-works/react-event-bus-client'
import { TextField, Button, Grid, Box, Paper } from '@material-ui/core'
import {
  endOfMonth,
  startOfMonth,
  formatISO9075,
  subMonths
} from 'date-fns'

export default function ExchangeRates () {
  const [exchangeRates, setExchangeRates] = useState([])
  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      currencyRequired: 'Currency is required',
      rateRequired: 'Rate is required',
      rateMin: 'Rate must be greater than 0',
      rateMax: 'Rate must be less than 100'
    }
  })

  const from = formatISO9075(startOfMonth(subMonths(new Date(), 1)))
  const to = formatISO9075(endOfMonth(subMonths(new Date(), 1)))

  const [listExchangeRate] = useEvent([
    {
      eventName: 'On Exchange Rate List',
      onSuccess: payload => setExchangeRates(payload)
    }
  ])

  const [createExchangeRate] = useEvent([
    {
      eventName: 'On Exchange Rate Create',
      onSuccess: payload => {
        console.log('trigger')
        exchangeRates.push(payload)
        setExchangeRates(exchangeRates)
      },
      onError: payload => {
        console.log('error', payload)
      }
    }
  ])

  useEventsOnViewLoad(() => {
    listExchangeRate.trigger()
  }, [listExchangeRate])

  function onSubmit (data) {
    createExchangeRate.trigger(data)
  }
  // return a grid with a list of exchange rates and a form to create a new one
  return (
    <Grid container spacing={3}>
      <Grid item md={6}>
        <h1>Create Exchange Rate</h1>
        <p>This is a form to create a new exchange rate.</p>
        <Paper>
          <Box p={3}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <TextField
                inputRef={register({
                  required: true,
                  minLength: 3,
                  maxLength: 3
                })}
                error={errors.currencyRequired}
                helperText={
                  errors.currencyRequired ? 'Currency is required' : false
                }
                name='currency'
                id='outlined-basic'
                label='Currency'
                variant='outlined'
              />
              <TextField
                inputRef={register({
                  required: true,
                  min: 0,
                  max: 100
                })}
                error={errors.rateRequired}
                helperText={errors.rateRequired ? 'Rate is required' : false}
                name='rate'
                id='outlined-basic'
                label='Rate'
                variant='outlined'
              />
              <TextField
                inputRef={register({
                  required: true,
                  minLength: 19,
                  maxLength: 19
                })}
                defaultValue={from}
                error={errors.from}
                helperText={errors.from ? 'From is required' : false}
                name='from'
                id='outlined-basic'
                label='From'
                variant='outlined'
              />
              <TextField
                inputRef={register({
                  required: true,
                  minLength: 19,
                  maxLength: 19
                })}
                defaultValue={to}
                error={errors.to}
                helperText={errors.to ? 'To is required' : false}
                name='to'
                id='outlined-basic'
                label='To'
                variant='outlined'
              />
              <Button type='submit' variant='contained' color='primary'>
                Create
              </Button>
            </form>
          </Box>
        </Paper>
      </Grid>
      <Grid item md={6}>
        <h1>Exchange Rates</h1>
        <p>This is a list of exchange rates.</p>
        <Paper>
          <Box p={3}>
            <ul>
              {exchangeRates.map(exchangeRate => (
                <li key={exchangeRate.pk + exchangeRate.sk}>
                  {exchangeRate.from} - {exchangeRate.to}{' '}
                  {exchangeRate.currency}: {exchangeRate.rate}
                </li>
              ))}
            </ul>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  )
}
