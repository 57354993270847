import reducer, * as actions from './slice'
import * as selectors from './selectors'
import name from './name'
import * as eventBus from './event-bus'

const exports = {
  reducer,
  actions,
  selectors,
  name,
  eventBus
}

export default exports
