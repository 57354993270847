import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
// import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
// import TextField from '@material-ui/core/TextField'
// import InputLabel from '@material-ui/core/InputLabel'
// import Select from '@material-ui/core/Select'
// import MenuItem from '@material-ui/core/MenuItem'
// import { useForm } from 'react-hook-form'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
// import ListItemAvatar from '@material-ui/core/ListItemAvatar'
// import Avatar from '@material-ui/core/Avatar'
// import ImageIcon from '@material-ui/icons/Image'
// import WorkIcon from '@material-ui/icons/Work'
// import BeachAccessIcon from '@material-ui/icons/BeachAccess'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  }
}))
export default function PayslipDetailDialog ({ open, payslip, handleClose }) {
  const classes = useStyles()
  return (
    <Dialog open={open} onClose={handleClose} fullWidth='100%'>
      <DialogTitle>Payslip</DialogTitle>
      <DialogContent>
        <Grid>
          <Grid>
            <Box>
              <Box fontWeight='fontWeightBold'>Name: {payslip.name}</Box>
              <Box fontWeight='fontWeightBold'>Email: {payslip.email}</Box>
              <Box fontWeight='fontWeightBold'>Due Date: {payslip.dueDate}</Box>
              <Box fontWeight='fontWeightBold'>Hours: {payslip.totalHoursNumber}</Box>
              <Box fontWeight='fontWeightBold'>Total: {payslip.total}</Box>
            </Box>
            <List className={classes.root}>
              {payslip.items.map((item, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={item.clientName}
                    secondary={item.projectName}
                  />
                  <ListItemText
                    primary={item.periodStart + ' - ' + item.periodEnd}
                    secondary={item.hours}
                  />
                  <ListItemText
                    primary={item.clientCurrency}
                    secondary={item.exchangeRate}
                  />
                  <ListItemText
                    primary={item.costRate}
                    secondary={item.costPerHourBRL}
                  />
                  <ListItemText
                    primary={item.total}
                    secondary={item.totalBRL}
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
