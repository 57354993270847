import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
// import InputLabel from '@material-ui/core/InputLabel'
// import Select from '@material-ui/core/Select'
// import MenuItem from '@material-ui/core/MenuItem'
import { useForm } from 'react-hook-form'

export default function PriceCost (props) {
  // const currencies = ['USD', 'GBP', 'BRL']
  const { register, handleSubmit, errors, watch } = useForm({
    defaultValues: {
      costRequired: 'Cost is required',
      priceRequired: 'Price is required',
      currencyRequired: 'Currency is required'
    }
  })

  watch('costRequired')
  watch('priceRequired')
  watch('currencyRequired')

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby='form-dialog-title'
    >
      <form onSubmit={handleSubmit(props.onSubmit)}>
        <DialogTitle id='form-dialog-title'>Set Price/Cost</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To set the price/cost of a contract, please fill the following
          </DialogContentText>
          <TextField
            inputRef={register({
              required: true,
              minLength: 1,
              maxLength: 6
            })}
            error={errors.cost}
            helperText={errors.cost ? 'This field is required' : false}
            name='cost'
            type='number'
            id='outlined-basic'
            label='Cost'
            variant='outlined'
          />
          <TextField
            inputRef={register({
              required: true,
              minLength: 1,
              maxLength: 6
            })}
            error={errors.price}
            helperText={errors.price ? 'This field is required' : false}
            name='price'
            type='number'
            id='outlined-basic'
            label='Price'
            variant='outlined'
          />
          <TextField
            inputRef={register({
              required: true,
              minLength: 1,
              maxLength: 6
            })}
            error={errors.price}
            helperText={errors.price ? 'This field is required' : false}
            name='currency'
            id='outlined-basic'
            label='Currency'
            variant='outlined'
          />
          {/* <InputLabel id='demo-simple-select-outlined-label'>
            Currency
          </InputLabel>
          <Select
            inputRef={register({
              required: true
            })}
            labelId='demo-simple-select-outlined-label'
            id='demo-simple-select-outlined'
            value={props.contract.currency}
            label='Currency'
            name='currency'
          >
            {currencies.map(currency => (
              <MenuItem key={currency} value={currency} name='curency'>
                {currency}
              </MenuItem>
            ))}
          </Select> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color='primary'>
            Cancel
          </Button>
          <Button type='submit' color='primary'>
            Set
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
