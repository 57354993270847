import React from 'react'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import PayslipDetailDialog from '../payslip-detail-dialog'
import { Checkbox } from '@material-ui/core'

export default function PayslipListItem ({ payslip, previousPeriodPayslip }) {
  const [open, setOpen] = React.useState(false)
  const handleOpenDetails = () => {
    setOpen(true)
  }
  const handleCloseDetails = () => {
    setOpen(false)
  }

  const calcPercentageDifference = (current, previous) => {
    if (current === 0 && previous === 0) {
      return 0
    }
    if (current === 0) {
      return -100
    }
    if (previous === 0) {
      return 100
    }
    return ((current - previous) / previous) * 100
  }

  const calcPercentageDifferenceColor = (current, previous) => {
    let percentageDifference = calcPercentageDifference(current, previous)
    if (percentageDifference < 0) {
      percentageDifference = percentageDifference * -1
    }
    if (percentageDifference < 10) {
      return 'white'
    }
    if (percentageDifference < 20) {
      return 'yellow'
    }
    if (percentageDifference < 30) {
      return 'orange'
    }
    return 'red'
  }

  const calcPercentageDifferenceSign = (current, previous) => {
    const percentageDifference = calcPercentageDifference(current, previous)
    if (percentageDifference > 0) {
      return '+'
    }
    return ''
  }

  const calcPercentageDifferenceString = (current, previous) => {
    const percentageDifference = calcPercentageDifference(current, previous)
    if (percentageDifference === 0) {
      return '(0%)'
    }
    return `(${calcPercentageDifferenceSign(
      current,
      previous
    )}${percentageDifference.toFixed(2)}%)`
  }

  const PercentageDifferenceView = ({ current, previous, propertyKey }) => {
    if (current && previous) {
      return (
        <span
          style={{
            color: calcPercentageDifferenceColor(
              current[propertyKey],
              previous[propertyKey]
            )
          }}
        >
          {calcPercentageDifferenceString(
            current[propertyKey],
            previous[propertyKey]
          )}
        </span>
      )
    }
    return <span />
  }

  return (
    <Grid item xs={12} sm={12} md={12} key={payslip.id}>
      <PayslipDetailDialog
        open={open}
        handleClose={handleCloseDetails}
        payslip={payslip}
      />
      <Paper>
        <Box p={2}>
          <Box display='flex' justifyContent='space-between'>
            <Box>
              <Box fontWeight='fontWeightBold'>{payslip.name}</Box>
              <Box>{payslip.projectName}</Box>
            </Box>
            <Box>
              <Box fontWeight='fontWeightBold'>{payslip.email}</Box>
              <Box>{payslip.from}</Box>
            </Box>
            <Box>
              <Box fontWeight='fontWeightBold'>{payslip.dueDate}</Box>
            </Box>
            <Box>
              <Box fontWeight='fontWeightBold'>{Math.round(payslip.totalHoursNumber, 2)}
                <PercentageDifferenceView
                  current={payslip}
                  previous={previousPeriodPayslip}
                  propertyKey='totalHoursNumber'
                />
              </Box>
            </Box>
            <Box>
              <Box fontWeight='fontWeightBold'>
                {payslip.total}
                <PercentageDifferenceView
                  current={payslip}
                  previous={previousPeriodPayslip}
                  propertyKey='totalNumber'
                />
              </Box>
            </Box>
            <Box>
              <Box fontWeight='fontWeightBold'>
                {Math.round(payslip.averageRate, 2)}
                <PercentageDifferenceView
                  current={payslip}
                  previous={previousPeriodPayslip}
                  propertyKey='averageRate'
                />
              </Box>
            </Box>
            <Box>
              <Button onClick={handleOpenDetails}>
                <Box fontWeight='fontWeightBold'>See Details</Box>
              </Button>
            </Box>
            <Box>
              <Checkbox
                checked={payslip.verified}
                onChange={() => {
                  payslip.verified = !payslip.verified
                }}
              />
            </Box>
          </Box>
        </Box>
      </Paper>
    </Grid>
  )
}
