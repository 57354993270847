import React from 'react'
import { Routes } from 'react-router-dom'
import { PrivateRoute } from '@emerald-works/react-auth'
import {
  Contracts,
  ExchangeRates,
  Payslips
} from './pages'

const ApplicationRoutes = () => {
  return (
    <Routes>
      <PrivateRoute path='/' element={<h1>Hi</h1>} />
      <PrivateRoute path='/exchange-rates' element={<ExchangeRates />} />
      <PrivateRoute path='/contracts' element={<Contracts />} />
      <PrivateRoute path='/Payslips' element={<Payslips />} />
    </Routes>
  )
}

export default ApplicationRoutes
