import React, { useState } from 'react'
import { useEvent } from '@emerald-works/react-event-bus-client'
import { Grid, Box, Paper, TextField } from '@material-ui/core'
import { format, startOfMonth, subMonths } from 'date-fns'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Button from '@material-ui/core/Button'
import { PayslipList } from './../../components'
export default function Payslips () {
  // return a view with a list of payslips and a button to generate payslips
  const [payslips, setPayslips] = useState({})
  const [previousPeriodPayslips, setPreviousPeriodPayslips] = useState({})
  const [only, setOnly] = useState('marcelo@devsquad.email')
  const [except, setExcept] = useState('marcelo2@devsquad.email')
  const [listPayslips] = useEvent([
    {
      eventName: 'On Payslip List',
      onSuccess: payload => {
        const reduced = Object.entries(payload).reduce((acc, [key, value]) => {
          acc[key] = value
          acc[key].averageRate = value.totalNumber / value.totalHoursNumber
          return acc
        }, {})
        setPayslips(reduced)
      }
    }
  ])
  const [listPreviousPeriodPayslips] = useEvent([
    {
      eventName: 'On Payslip List',
      onSuccess: payload => {
        const reduced = Object.entries(payload).reduce((acc, [key, value]) => {
          acc[key] = value
          acc[key].averageRate = value.totalNumber / value.totalHoursNumber
          return acc
        }, {})
        setPreviousPeriodPayslips(reduced)
      }
    }
  ])

  const [generatePayslips] = useEvent([
    {
      eventName: 'On Payslip Generate',
      onSuccess: payload => {
        console.log('generated')
      }
    }
  ])
  const [period, setPeriod] = React.useState(
    format(startOfMonth(subMonths(new Date(), 1)), 'yyyy-MM')
  )
  const periods = []
  for (let i = 0; i < 5; i++) {
    periods.push(format(subMonths(new Date(), i), 'yyyy-MM'))
  }

  const handleGeneratePayslips = () => {
    const [year, month] = period.split('-')
    console.log('only', only, 'except', except)
    const onlyList = only.split(',').filter(email => email.indexOf('@') > -1)
    const onlyParameter = onlyList.length > 0 ? onlyList : undefined
    const exceptList = except
      .split(',')
      .filter(email => email.indexOf('@') > -1)
    const exceptParameter = exceptList.length > 0 ? exceptList : undefined
    console.log('{ year, month, only: onlyList, except: exceptList }', {
      year,
      month,
      only: onlyParameter,
      except: exceptParameter
    })
    generatePayslips.trigger({
      year,
      month,
      only: onlyParameter,
      except: exceptParameter
    })
  }

  const handleListPayslips = () => {
    const [year, month] = period.split('-')
    listPayslips.trigger({ year, month, only, except })
    const previousPeriod = format(
      subMonths(new Date(year, month - 1), 1),
      'yyyy-MM'
    )
    const [previousYear, previousMonth] = previousPeriod.split('-')
    listPreviousPeriodPayslips.trigger({
      year: previousYear,
      month: previousMonth,
      only,
      except
    })
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box display='flex' justifyContent='space-between'>
          <Box>
            <FormControl>
              <InputLabel id='period-label'>Period</InputLabel>
              <Select
                labelId='period-label'
                id='period-select'
                value={period}
                onChange={e => setPeriod(e.target.value)}
              >
                {periods.map(period => (
                  <MenuItem key={period} value={period}>
                    {period}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box>
            <FormControl>
              <TextField
                id='only'
                label='Only'
                value={only}
                onChange={e => setOnly(e.target.value)}
              />
            </FormControl>
          </Box>
          <Box>
            <FormControl>
              <TextField
                id='except'
                label='Except'
                value={except}
                onChange={e => setExcept(e.target.value)}
              />
            </FormControl>
          </Box>
          <Box>
            <Button
              variant='contained'
              color='primary'
              onClick={handleListPayslips}
            >
              List
            </Button>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Box display='flex' justifyContent='space-between'>
            <Box>
              <Box fontWeight='fontWeightBold'>Payslips</Box>
            </Box>
            <Box>
              <Box fontWeight='fontWeightBold'>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={handleGeneratePayslips}
                >
                  Generate
                </Button>
              </Box>
            </Box>
          </Box>
          <Box display='flex' justifyContent='space-between'>
            <Box>
              <Box fontWeight='fontWeightBold'>Count: {payslips.length}</Box>
            </Box>
            <Box>
              <Box fontWeight='fontWeightBold'>
                Total:{' '}
                {Object.entries(previousPeriodPayslips).reduce(
                  (total, [key, payslip]) => total + payslip.totalNumber,
                  0
                )}{' '}
                {'>'}
                {Object.entries(payslips).reduce(
                  (total, [key, payslip]) => total + payslip.totalNumber,
                  0
                )}
              </Box>
              <Box fontWeight='fontWeightBold'>
                Hours:{' '}
                {Object.entries(previousPeriodPayslips).reduce(
                  (total, [key, payslip]) => total + payslip.totalHoursNumber,
                  0
                )}{' '}
                {'>'}
                {Object.entries(payslips).reduce(
                  (total, [key, payslip]) => total + payslip.totalHoursNumber,
                  0
                )}
              </Box>
            </Box>
          </Box>
        </Paper>
        <PayslipList
          payslips={payslips}
          previousPeriodPayslips={previousPeriodPayslips}
        />
      </Grid>
    </Grid>
  )
}
