import React from 'react'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'

export default function ContractList ({ contracts, handleClickOpen }) {
  return (
    <Grid container spacing={3}>
      {contracts.map(contract => (
        <Grid item xs={12} sm={12} md={12} key={contract.id}>
          <Paper>
            <Box p={2}>
              <Box display='flex' justifyContent='space-between'>
                <Box>
                  <Box fontWeight='fontWeightBold'>{contract.clientName}</Box>
                  <Box>{contract.projectName}</Box>
                </Box>
                <Box>
                  <Box fontWeight='fontWeightBold'>{contract.email}</Box>
                  <Box>{contract.from}</Box>
                </Box>
                <Box>
                  <Box fontWeight='fontWeightBold'>{contract.currency}</Box>
                </Box>
                <Box>
                  <Button
                    onClick={() => {
                      handleClickOpen(contract)
                    }}
                  >
                    <Box fontWeight='fontWeightBold'>Set Price/Cost</Box>
                  </Button>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Grid>
      ))}
    </Grid>
  )
}
