import React from 'react'
import PayslipListItem from '../payslip-list-item'

export default function PayslipList ({ payslips, previousPeriodPayslips }) {
  return (
    <div className='payslip-container'>
      {Object.keys(payslips).map(key => {
        return <PayslipListItem key={key} payslip={payslips[key]} previousPeriodPayslip={previousPeriodPayslips[key]} />
      })}
    </div>
  )
}
