import React, { useState } from 'react'
import { useEvent } from '@emerald-works/react-event-bus-client'
import { Grid, Box, Checkbox } from '@material-ui/core'
import { format, startOfMonth, subMonths } from 'date-fns'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Button from '@material-ui/core/Button'
import { PriceCostDialog, ContractList } from './../../components'

export default function Contracts () {
  const [contracts, setContracts] = useState([
    // {
    //   clientName: 'DevSquad - Institute',
    //   projectName: 'Rocketseat',
    //   id: 4245151,
    //   email: 'fellipe@devsquad.email',
    //   from: '2022-06-01',
    //   currency: 'BRL'
    // }
  ])
  const [open, setOpen] = React.useState(false)
  const [contract, setContract] = React.useState({})

  const [period, setPeriod] = React.useState(format(startOfMonth(subMonths(new Date(), 1)), 'yyyy-MM'))

  const [listCostlessContracts] = useEvent([
    {
      eventName: 'On Contract List',
      onSuccess: payload => setContracts(payload)
    }
  ])

  const [createContract] = useEvent([
    {
      eventName: 'On Contract Create',
      onSuccess: payload => {
        console.log('trigger')
        listCostlessContracts.trigger({ period })
      },
      onError: payload => {
        console.log('error', payload)
      }
    }
  ])

  const handleClickOpen = selectedContract => {
    setOpen(true)
    setContract(selectedContract)
  }

  const handleClose = () => {
    setOpen(false)
  }
  const periods = []
  for (let i = 0; i < 5; i++) {
    periods.push(format(subMonths(new Date(), i), 'yyyy-MM'))
  }

  function handleChange (data) {
    setPeriod(data.target.value)
    listCostlessContracts.trigger({ period })
  }
  function handleSet (data) {
    handleClose()
    // console.log('data', { ...contract, ...data, projectName: contract.clientName })
    createContract.trigger({ ...contract, ...data, projectName: contract.clientName })
  }
  return (
    <div>
      <Grid container spacing={3}>
        <Box display='flex' justifyContent='space-between'>
          <Box>
            <Box fontWeight='fontWeightBold'>Period</Box>
            <Box>
              <FormControl variant='outlined' margin='normal' required>
                <InputLabel id='demo-simple-select-outlined-label'>
                  Period
                </InputLabel>
                <Select
                  labelId='demo-simple-select-outlined-label'
                  id='demo-simple-select-outlined'
                  value={period}
                  onChange={handleChange}
                  label='Period'
                >
                  {periods.map(period => (
                    <MenuItem key={period} value={period}>
                      {period}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box>
            <Box fontWeight='fontWeightBold'>
              Only show contracts missing a rate
            </Box>
            <Box>
              <FormControl variant='outlined' margin='normal' required>
                <InputLabel id='demo-simple-select-outlined-label'>
                  Only contracts missing a rate
                </InputLabel>
                <Checkbox
                  label='Only contracts missing a rate'
                  name='onlyCostless'
                />
              </FormControl>
            </Box>
          </Box>
          <Box>
            <Button>
              <Box fontWeight='fontWeightBold'>Create Contract</Box>
            </Button>
          </Box>
        </Box>
        <ContractList contracts={contracts} handleClickOpen={handleClickOpen} />
        <PriceCostDialog
          open={open}
          handleClose={handleClose}
          onSubmit={handleSet}
          contract={contract}
        />
      </Grid>
    </div>
  )
}
